<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper :field="field" :input-group="true">
      <b-form-file
        v-model="$v.value.$model"
        class="form-control border-0"
        :class="[validationClass]"
        :browse-text="$t('formHelper.browse')"
        :placeholder="$t('formHelper.noFileChosen')"
        :accept="accept"
        :multiple="true"
        :disabled="isDisabled"
        @input="onInput"
      />
      <!------------ START: Append file size ------------>
      <div v-if="filesSelected" class="input-group-append">
        <span class="input-group-text">
          {{ selectedFilesSize }}
        </span>
      </div>
      <!------------ END: Append file size ------------>
      <!------------ START: Append clear icon ------------>
      <div v-if="filesSelected" class="input-group-append">
        <span
          class="input-group-text cursor-pointer"
          @click="$v.value.$model = null"
        >
          <i class="fal fa-xmark" />
        </span>
      </div>
      <!------------ END: Append clear icon ------------>
      <!------------ START: Hints ------------>
      <template #hints>
        <!------------ START: File type hint ------------>
        <div v-if="accept">
          {{ $t("formHelper.acceptedFileTypes", { fileTypes: accept }) }}
        </div>
        <!------------ END: File type hint ------------>
        <!------------ START: Upload size limit hint ------------>
        <div>
          {{ $t("formHelper.uploadSizeLimit", { limit: sizeLimit }) }}
        </div>
        <!------------ END: Upload size limit hint ------------>
      </template>
      <!------------ END: Hints ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
export default {
  components: { FieldWrapper },
  mixins: [base],
  props: {},
  data() {
    return {};
  },
  computed: {
    accept: function () {
      // If no accept is set, return empty string
      if (!this.field.accept) {
        return "";
      }
      if (Array.isArray(this.field.accept)) {
        // If accept prop is array return elements comma separated
        return this.field.accept.join(", ");
      } else if (typeof this.field.accept === "string") {
        // Return accept if it's already a string
        return this.field.accept;
      } else {
        // Else return empty string
        return "";
      }
    },
    sizeLimit: function () {
      // Get size prop or fallback value
      let size = this.field.size ?? 10000;
      // Return size in kb or mb
      return size > 1000 ? size / 1000 + " MB" : size + " KB";
    },
    filesSelected: function () {
      // Return if file[s] are selected, either as array or single file
      return Array.isArray(this.value) ? !!this.value.length : !!this.value;
    },
    selectedFilesSize: function () {
      let files = this.value;
      // Convert file[s] to array
      if (!Array.isArray(files)) {
        files = [this.value];
      }
      // Get sum of all file sizes
      let size = 0;
      files.forEach(file => (size += file.size));
      // Convert size to kb
      size = size / 1024;
      // Return size in kb or mb
      return size > 1000
        ? (size / 1000).toFixed(2) + " MB"
        : size.toFixed(2) + " KB";
    }
  },
  mounted() {},
  methods: {
    onInput(input) {
      // Get max upload size
      let size = this.field.size ?? 10000;
      // Convert file[s] to array
      if (!Array.isArray(input)) {
        input = [input];
      }
      let reset = [];
      // Iterate over files
      for (let i = 0; i < input.length; i++) {
        let file = input[i];
        // If file exceeds upload size limit, push it to reset array
        if (file.size / 1024 > size) {
          reset.push(file);
        }
      }
      // If reset array has items
      if (reset.length) {
        // Fire toast warning
        this.$toast.fire({
          icon: "warning",
          title: this.$tc("formHelper.fileSizeLimitError", input.length, {
            size: this.sizeLimit
          })
        });
        // Clear model
        this.$v.value.$model = this.field.multiple ? [] : null;
      }
      this.$emit("input", this.$v.value.$model);
    }
  }
};
</script>

<style lang="scss">
.custom-file {
  .custom-file-label {
    height: 39px;
  }
  + .input-group-append {
    .input-group-text {
      border-left: 0;
    }
  }
}
</style>
